<template lang="pug">
  .login.inline-form
    h3 {{ t("pages.login.page_title") }}
    login-form(@submit="submit")

    .uk-text-center
      p
        | {{ t("pages.login.create_user") }}
        br
        //- a.uk-link-text.uk-display-inline-block(
        //-   @click.prevent.stop="$emit('didTapSignUp')"
        //- )
        //-   | {{ t("pages.login.signup_now") }}
        router-link.uk-link-text.uk-display-inline-block(
          :to="{ name: 'SignUp' }"
        )
          | {{ t("pages.login.signup_now") }}

    .uk-text-center
      //- router-link.uk-margin-top.uk-link-text.uk-display-block(:to="{ name: 'ResendConfirmation' }")
      //-   | {{ t("pages.login.resend_confirmation") }}
      router-link.uk-link-text.uk-display-block.uk-margin-top(:to="{ name: 'ResetPassword' }")
        | {{ t("pages.login.forgot_password") }}
</template>

<script>
import { mapGetters } from "vuex"
import LoginForm from "../components/forms/LoginForm"

export default {
  components: {
    LoginForm
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: "isUserLoggedIn"
    })
  },
  methods: {
    async submit(credentials, setSubmitting) {
      try {
        await this.$store.dispatch("login", credentials)
      } catch (error) {
        this.notify(error.response.data.error, "danger")
      }
      setSubmitting(false)
    }
  }
}
</script>
