// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import Vue from "vue"
import Vuikit from "vuikit"
import Vuelidate from "vuelidate"
import VuikitIcons from "@vuikit/icons"
import UIkit from "uikit"
import Icons from "uikit/dist/js/uikit-icons"
import App from "../app.vue"
import i18n from "../mixins/i18n"
import common from "../mixins/common"
import { store } from '../store'

I18n.locale = document.querySelector("html").getAttribute("lang") || "de"

Vue.use(Vuelidate)
Vue.use(Vuikit)
Vue.use(VuikitIcons)
UIkit.use(Icons)

Vue.mixin(i18n)
Vue.mixin(common)

document.addEventListener("DOMContentLoaded", () => {
  // if (['staging', 'production'].includes(environment())) {
  //   window.onerror = appsignalErrorHandler
  //   Vue.config.errorHandler = (error) =>
  //     appsignalErrorHandler(error.message, 'vue-error', 0, 0, error)
  // }

  const App = () => import("../app")

  const app = new Vue({
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
  window.app = app
})
