import UIkit from "uikit"

export default {
  namespaced: false,
  actions: {
    notify(context, { message, status = "danger" }) {
      UIkit.notification(message, { status })
    }
  }
}
