import http from "../services/http"
import notify from "./notify"

const serverCheck = (endpoint, name) => async value => {
  if (value === "") return true

  try {
    const result = await http.get(endpoint, { params: { [name]: value } })
    return !result.data
  } catch (error) {
    notify(error.message, "danger")
    return false
  }
}

export default serverCheck
