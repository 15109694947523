<template lang="pug">
  button.uk-button(
    :class="className"
    :disabled="shouldDisable"
    type="submit"
  )
    span(v-if="!isSubmitting") {{ label }}
    span(v-else uk-spinner)
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: "uk-button-primary"
    },
    isDisabled: {
      type: Boolean,
      deafult: false
    }
  },
  computed: {
    shouldDisable() {
      return this.isSubmitting || this.isDisabled
    }
  }
}
</script>

<style scoped>
.uk-button-primary:active,
.uk-button-primary:hover,
.uk-button-primary:focus {
  background: transparent !important;
  color: #999;
}
</style>
