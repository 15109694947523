<template lang="pug">
  div
    .control(:class="controlClass")
      .plus(:uk-icon="`icon: plus; ratio: ${ratio}`" @click.prevent.stop="add")
      .minus(:uk-icon="`icon: minus; ratio: ${ratio}`" @click.prevent.stop="remove")
      .close(:uk-icon="`icon: close; ratio: ${ratio}`" @click.prevent.stop="removeAll")
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

export default {
  props: {
    controlStyle: {
      type: String,
      default: "normal"
    },
    lineItem: {
      type: Object,
      required: true
    },
    dateKey: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState("cart", ["isSavingCart"]),
    ...mapGetters("catalog", ["findProduct"]),

    ratio() {
      switch (this.controlStyle) {
        case "normal":
          return 0.75
        default:
          return 1
      }
    },
    product() {
      const product = this.findProduct(this.lineItem.productId) || {}
      return {
        ...product,
        ...{
          id: this.lineItem.productId,
          name: this.lineItem.name,
          price: this.lineItem.price
        }
      }
    },
    controlClass() {
      let list = [this.controlStyle]
      if (this.isSavingCart) {
        list.push("inactive")
      }
      return list
    }
  },
  methods: {
    ...mapActions("cart", ["addProduct", "removeProduct", "removeAllProducts"]),

    add() {
      if (this.isSavingCart) {
        return
      }
      this.addProduct({ product: this.product, dateKey: this.dateKey, variants: this.lineItem.variants })
    },
    remove() {
      if (this.isSavingCart) {
        return
      }
      this.removeProduct({ product: this.product, dateKey: this.dateKey, variantsKey: this.lineItem.variantsKey })
    },
    removeAll() {
      if (this.isSavingCart) {
        return
      }
      this.removeAllProducts({ product: this.product, dateKey: this.dateKey, variantsKey: this.lineItem.variantsKey })
    }
  }
}
</script>

<style lang="scss" scoped>
.control {
  border: 1px #ddd solid;
  border-radius: 5px;
  display: inline-block;
  transition: opacity 300ms ease-out;

  div {
    border-right: 1px #ddd solid;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;

    &:last-child {
      border-right: 0;
    }
  }

  &.big div {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
  }

  &.inactive {
    opacity: 0.5;
  }
}
</style>
