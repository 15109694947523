<template lang="pug">
  form.full-height(@submit.prevent="submit" novalidate)
    div(v-if="email")
      | {{ t("pages.login.call_to_action") }}
      input(type="hidden" name="email" v-model="form.email")
    form-element(v-else)
      input-field(
        type="email"
        name="email"
        :label="t('pages.login.form.fields.email')"
        v-model="form.email"
        :validations="$v.form.email"
        :disabled="isFormDisabled"
        :fullscreen="true"
        icon="mail"
      )
    form-element
      input-field(
        type="password"
        name="password"
        :label="t('pages.login.form.fields.password')"
        v-model="form.password"
        :validations="$v.form.password"
        :disabled="isFormDisabled"
        :fullscreen="true"
        icon="lock"
      )
    checkbox(
      name="remember"
      :label="t('pages.login.form.fields.remember')"
      v-model="form.remember"
      :validations="$v.form.remember"
      :disabled="isFormDisabled"
    )
    .uk-margin-medium
      submit-button(:is-submitting="isFormDisabled" :label="t('pages.login.form.submit')")
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import InputField from "../InputField"
import SubmitButton from "../SubmitButton"
import FormElement from "../FormElement"
import Checkbox from "../Checkbox"
import formMixin from "../../mixins/form"

export default {
  components: {
    InputField,
    SubmitButton,
    FormElement,
    Checkbox
  },
  mixins: [formMixin],
  data() {
    return {
      form: {
        email: "",
        password: "",
        remember: false
      }
    }
  },
  computed: {
    email() {
      return window.state.email
    }
  },
  created() {
    this.form.email = this.email
  },
  validations() {
    if (this.email) {
      return {
        form: {
          email: {},
          password: { required },
          remember: {}
        }
      }
    } else {
      return {
        form: {
          email: {
            required,
            email
          },
          password: { required },
          remember: {}
        }
      }
    }
  }
}
</script>
