export const PATH = "/api/carts"
export const LINE_ITEMS_PATH = "/api/carts/current/line_items"
export const ADDRESS_PATH = "/api/carts/current/addresses"
export const PAYMENT_PATH = "/api/carts/current/payment_methods"
export const DISCOUNT_PATH = "/api/carts/current/discounts"
export const ORDER_PROPERTIES_PATH = "/api/carts/current/order_properties"
export const ORDER_PATH = "/api/orders"
export const OFFERS_PATH = "/api/offers"

export const OUTPUT_DATE_FORMAT = "dddd l"
export const DEFAULT_ORDER_LEAD_TIME_IN_DAYS = 2
export const ACCOUNT_INFO_PERMALINK = "account-info"
export const MIN_HOURS_FOR_NEXT_DAY_ORDER = 12

export const WEEKDAYS = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag"
]

export const CATERING_CATEGORY_KEYS = ["morning", "noon", "afternoon", "drink"]
export const CATERING_GROUPS_KEYS = ["morning", "noon", "afternoon"]
export const DELIVERY_TIMES = {
  "vormittags": ["07:00", "11:30"],
  "mittags": ["11:30", "14:30"],
  "nachmittags": ["14:30", "16:30"]
}

export const TIME_STEPS = 15
export const CATERING_REQUIRED_FORM_FIELDS = [
  "date",
  "personCount",
  "reference"
]

export const CATERING_ADDRESS_FORM_FIELDS = [
  "company",
  "salutation",
  "firstName",
  "lastName",
  "location",
  "street",
  "zipCode",
  "city",
  "phone"
]

export const CATERING_INVOICE_ADDRESS_FORM_FIELDS = [
  "invoiceCompany",
  "invoiceSalutation",
  "invoiceFirstName",
  "invoiceLastName",
  "invoiceStreet",
  "invoiceZipCode",
  "invoiceCity",
  "referenceText"
]

export const CATERING_ROUTES_TO_CATALOG = {
  "Category": [1,2,3],
  "Drinks": [4],
  "Equipment": [5],
  "Staff": [6],
}

export const CATERING_CATALOG_IMAGES = {
  "catalog-id-2": "/images/catering-mittag.jpg",
  "catalog-id-3": "/images/catering-nachmittags.jpg",
  "catalog-id-4": "/images/catering-getraenke.jpg",
}

export const CATERING_LUNCH_OPTIONS = [
  {
    keys: [1, 2, 3],
    personCount: 10
  },
  {
    keys: [4],
    personCount: 10
  }
]
export const CATERING_LUNCH_OPTIONS_MEAL_COUNT = 2

export const CATERING_MIN_PERSON_COUNT = 10
