import notify from "../helpers/notify"
import Page from "../components/Page"

export default {
  components: {
    Page
  },
  methods: {
    notify,
    goBack() {
      history.back()
    },
    goTo(route) {
      this.$router.push(route)
    },
    goToPage(permalink) {
      this.$router.push({ name: "page", param: { permalink } })
    },
    async logout(redirect) {
      await this.$store.dispatch("logout")
      if (redirect !== false) {
        document.location.href = "/"
      }
    },
    showServerError() {
      notify("Es ist ein Fehler aufgetreten, bitte versuche es erneut.", "danger")
    }
  }
}
