import serverCheck from "../helpers/serverCheck"

export default {
  data() {
    return {
      isSubmitting: false,
      serverErrors: {}
    }
  },
  computed: {
    isFormDisabled() {
      return this.isSubmitting
    }
  },
  methods: {
    serverCheckWithTakenGuard(endpoint, paramName, attr) {
      return value => {
        if (!this.$v.form[attr][attr]) {
          return true
        }
        return serverCheck(endpoint, paramName)(value)
      }
    },
    submit() {
      console.log("submitting")
      this.$v.$touch()
      this.isSubmitting = true
      if (this.$v.$invalid) {
        this.isSubmitting = false
      } else {
        this.$emit(
          "submit",
          { ...this.form },
          v => (this.isSubmitting = !!v),
          errors => (this.serverErrors = errors)
        )
      }
    }
  }
}
