import axios from "axios"
import router from "../config/router"

axios.defaults.headers.common["Accept"] = "application/json"

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      router.push({ name: "SignIn" })
    }
    return Promise.reject(error)
  }
)

export const setAuthHeaderPlugin = store => {
  store.subscribe(({ type, payload }) => {
    if (type == "setUser") {
      axios.defaults.headers.common["Authorization"] = payload.token
    }
  })
}

export default axios
