import http from "./http"
const PATH = "/api/pages"
const NAVIGATION_PATH = "/api/navigations"

export default {
  async get(permalink) {
    const { data: page } = await http.get(`${PATH}/${permalink}`)
    return page
  },

  async getFooter() {
    const { data: pages } = await http.get(`${PATH}/?group=footer-`)
    return pages
  },

  async getNavigation() {
    const { data } = await http.get(NAVIGATION_PATH)
    return data
  }
}
