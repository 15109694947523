import http from "./http"
import { ORDER_PATH } from "../config/constants"

export default {
  async index() {
    const { data: result } = await http.get(ORDER_PATH)
    return (result && result.orders) || []
  },

  async cancelOrder({ id, dateKey }) {
    await http.delete(`${ORDER_PATH}/${id}?date_key=${dateKey}`)
  }
}
