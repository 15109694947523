<template lang="pug">
  ul
    li(v-for="link in links")
      template(v-if="link.children && link.children.length")
        a(href="#" :class="{'router-link-exact-active router-link-active': hasActiveChild(link)}" @click.prevent) {{ link.title }}

        vk-dropdown
          vk-nav-dropdown
            li(
              v-for="deepLink in link.children"
              :key="deepLink.id"
            )
              router-link(
                :to="deepLink.to"
                @click.native="$emit('didSelectNavigationLink')"
              ) {{ deepLink.title }}

      router-link(
        v-else
        :to="link.to"
        @click.native="$emit('didSelectNavigationLink')"
      ) {{ link.title }}

</template>

<script>
import { WEEKDAYS, MIN_HOURS_FOR_NEXT_DAY_ORDER, CATERING_CATEGORY_KEYS } from "../config/constants"
import moment from "moment"
import Settings from '../config/settings'
import { mapState } from "vuex"

function nextWeekday() {
  const date = moment()
  switch (date.weekday()) {
    case 5:
    case 6:
      return WEEKDAYS[0]
    case 0:
    case 1:
      return WEEKDAYS[1]
  }
  if (date.hours(MIN_HOURS_FOR_NEXT_DAY_ORDER) > new Date()) {
    return WEEKDAYS[date.weekday() + 1] || WEEKDAYS[0]
  }
  return WEEKDAYS[date.weekday() + 2] || WEEKDAYS[0]
}

function buildLink(orderPropertiesId, link) {
  const ref = link.children?.length ? link.children[0] : link
  if (ref.type === 'product_group') {
    return {
      title: link.title,
      to: {
        name: orderPropertiesId ? "Category" : "CategoryPreview",
        meta: orderPropertiesId ? { key: "products" } : undefined,
        params: {
          id: orderPropertiesId,
          category: ref.id
        }
      },
      children: link.children?.length ? link.children.map(link => buildLink(orderPropertiesId, link)) : undefined
    }
  } else {
    return {
      title: link.title,
      to: {
        name: "Page",
        params: {
          permalink: link.id
        }
      },
      children: link.children?.length ? link.children.map(l => buildLink(orderPropertiesId, link)) : undefined
    }
  }
}

function currentOrderPropertiesId(router, route, lineItems, orderProperties) {
  const catering = router.options.routes.find(r => r.name === "Catering")
  const id = catering.children.find(c => c.name == route.name) ? route.params.id : null
  if (id) {
    return id
  }
  if (lineItems.length && lineItems[0].variants) {
    try {
      return Object.values(lineItems[0].variants)[0]?.split('|')[0]
    } catch (error) {
      console.error("id not found: ", error)
    }
  }
  if (orderProperties.length) {
    return orderProperties[0].uuid
  }
  return null
}

export default {
  props: {
    reduced: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      WEEKDAYS
    }
  },
  computed: {
    ...mapState("page", ["navigation"]),
    ...mapState("cart", ["lineItems", "orderProperties"]),

    links() {
      if (Settings.shop === "catering") {
        // return [
        // { to: { name: "Home" }, title: I18n.t("pages.navigation.catering.home") },
        //   ...CATERING_CATEGORY_KEYS.map(category => {
        //     const title = I18n.t(`pages.navigation.catering.${category}`)
        //     return { to: { name: "CategoryPreview", params: { category: title.toLowerCase() } }, title }
        //   })
        // ]

        const id = currentOrderPropertiesId(this.$router, this.$route, this.lineItems, this.orderProperties)
        return this.navigation.map(link => buildLink(id, link))
      }
      let links = [{ to: { name: "Home" }, title: I18n.t("pages.navigation.katine.home") }]

      if (this.reduced) {
        const weekday = nextWeekday()
        links.push({
          to: {
            name: "Catalog",
            params: {
              weekday: weekday.toLowerCase()
            },
          },
          title: "Bestellen"
        })
      } else {
        WEEKDAYS.forEach(weekday => {
          links.push({ to: { name: "Catalog", params: { weekday: weekday.toLowerCase() } }, title: weekday })
        })
      }
      return links
    }
  },
  methods: {
    hasActiveChild(link) {
      const $route = this.$route
      const foundLink = link.children.find(child => {
        if (child.to.name !== $route.name || Object.keys(child.to.params).length !== Object.keys($route.params).length) {
          return false
        }

        return Object.keys(child.to.params).every(key => {
          return child.to.params[key] === $route.params[key]
        })
      })
      window.$route = this.$route
      window.link = link
      
      return Boolean(foundLink)
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  letter-spacing: 2px;
}

.uk-navbar-nav > li > a {
  @media (max-width: 768px) {
    padding: 0 8px;
  }
}
</style>
