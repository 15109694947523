import CatalogService from "../services/catalogService"
import moment from "moment"
import { MIN_HOURS_FOR_NEXT_DAY_ORDER, OUTPUT_DATE_FORMAT } from "../config/constants"

function findHoliday(holidays, date) {
  return holidays.find(holiday => moment(holiday.date).isSame(date, "day")) || null
}

function isOrderableOn(state, rootGetters, date) {
  const holiday = findHoliday(state.holidays, moment(date))
  if (holiday) {
    return false
  }

  if (!isShopActiveOn(rootGetters, date)) {
    return false
  }
  const currentDate = moment()
  const checkDate = moment(date)

  if (
    checkDate > currentDate
    && checkDate.weekday() === 0
    && [5,6].includes(currentDate.weekday())
    && checkDate.subtract(3, "days") < currentDate
  ) {
    return false
  }

  const orderLeadTime = rootGetters.orderLeadTime
  return checkDate.subtract(orderLeadTime, "days").hours(MIN_HOURS_FOR_NEXT_DAY_ORDER) > currentDate
}

function isShopActiveOn(rootGetters, date) {
  const organization = rootGetters.organization
  if (!organization || !organization.shopActiveAt) {
    return false
  }
  if (moment(organization.shopActiveAt) > moment(date)) {
    return false
  }

  return true
}

function isValidDate(filterDate, date, dateEnd) {
  if (!dateEnd) {
    return moment(date).isSame(filterDate, "day") || moment(filterDate).isAfter(date, "day")
  }
  return filterDate.isBetween(date, dateEnd) || (moment(date).isSame(filterDate, "day") && moment(date).isSame(dateEnd, "day"))
}

function formattedDate(date) {
  return moment(date).format(OUTPUT_DATE_FORMAT)
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    catalogs: [],
    holidays: [],
    variants: {}
  },
  getters: {
    findProduct: state => id => {
      for (let i=0; i < state.catalogs.length; i++) {
        const product = state.catalogs[i].products.find(prd => prd.id === id)
        if (product) {
          return {
            ...product,
            ...{ catalogId: state.catalogs[i].id }
          }
        }
      }
      return null
    },
    isOrderableOn: (state, _, __, rootGetters) => date => {
      return isOrderableOn(state, rootGetters, date)
    },
    isShopActiveOn: (state, _, __, rootGetters) => date => {
      return isShopActiveOn(rootGetters, date)
    },
    catalogsForDate: (state, _, __, rootGetters) => date => {
      const orderLeadTime = rootGetters.orderLeadTime
      const productFilter = (product, filterDate) => {
        return !product.date || isValidDate(filterDate, product.date, product.dateEnd)
      }
      const swapWeekDate = moment().weekday(4).hours(MIN_HOURS_FOR_NEXT_DAY_ORDER).minutes(0).seconds(0)
      const isCurrentWeek = moment() < swapWeekDate || date > moment().weekday(5)
      const currentDate = moment(isCurrentWeek ? date : moment(date).add(7, "days")).subtract(1, "hour")
      const nextDate = moment(currentDate).add(7, "days")
      const currentHoliday = findHoliday(state.holidays, currentDate)
      const nextHoliday = findHoliday(state.holidays, nextDate)
      const firstCatalog = state.catalogs[0]
      window.currentDate = currentDate

      return state.catalogs.map(catalog => {
        return {
          title: catalog.title,
          id: catalog.id,
          current: {
            key: `left-${currentDate.toDate().getTime()}`,
            date: currentDate,
            datePlain: moment(currentDate).toDate(),
            title: formattedDate(currentDate),
            products: currentHoliday ? [] : catalog.products.filter(product => productFilter(product, currentDate)),
            // isOrderable: currentHoliday ? false : moment(currentDate).subtract(orderLeadTime, "days").hours(MIN_HOURS_FOR_NEXT_DAY_ORDER) > new moment(),
            isOrderable: isOrderableOn(state, rootGetters, currentDate),
            holiday: firstCatalog === catalog ? currentHoliday : null
          },
          next: {
            key: `right-${nextDate.toDate().getTime()}`,
            date: nextDate,
            datePlain: moment(nextDate).toDate(),
            title: formattedDate(nextDate),
            products: nextHoliday ? [] : catalog.products.filter(product => productFilter(product, nextDate)),
            // isOrderable: nextHoliday ? false : moment(nextDate).subtract(orderLeadTime, "days") > new Date(),
            isOrderable: isOrderableOn(state, rootGetters, nextDate),
            holiday: firstCatalog === catalog ? nextHoliday : null
          }
        }
      })
      .filter(pack => pack.current.products.length || pack.next.products.length || pack.current.holiday || pack.next.holiday)
    }
  },
  mutations: {
    setCatalogs(state, catalogs) {
      state.catalogs = catalogs
    },
    setHolidays(state, holidays) {
      state.holidays = holidays
    },
    setVariantSelections(state, variants) {
      state.variants = variants
    }
  },
  actions: {
    async loadCatalogs({ state, commit }, weekday) {
      state.isLoading = true
      try {
        const data = await CatalogService.list()
        commit("setCatalogs", data.catalogs)
        commit("setHolidays", data.holidays)
      } catch (error) {
        commit("setCatalogs", [])
      }
      state.isLoading = false
    },
    setVariantSelections({ commit }, variants) {
      commit("setVariantSelections", variants)
    }
  }
}
