<template lang="pug">
  div
    input.uk-checkbox(
      type="checkbox"
      :id="name"
      :name="name"
      :disabled="disabled"
      v-model="checked"
    )
    label(v-if="isLabelHtml" :for="name" v-html="label")
    label(v-else :for="name") {{ label }}
    validation-errors(:label="label" :validations="validations")
</template>

<script>
import ValidationErrors from "./ValidationErrors"

export default {
  components: {
    ValidationErrors
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    isLabelHtml: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    },
    validations: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["input"],
  data() {
    return {
      checked: this.value
    }
  },
  watch: {
    checked(newValue) {
      if (this.validations) {
        this.validations.$touch()
      }
      this.$emit("input", newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  padding-left: 1ex;
  line-height: 2.7ex;
  font-size: 14px;
}
</style>
