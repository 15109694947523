<template lang="pug">
  div
    vk-offcanvas-content.uk-position-absolute
    .uk-text-center
      a.cart-button(
        href="#"
        @click.prevent.stop="showCart = !showCart"
      )
        span(uk-icon="cart")
        .uk-text-smaller.uk-text-center
          | {{ t("navigation.cart") }}
          span.amount(v-show="itemAmount > 0") ({{ itemAmount }})

    vk-offcanvas(flipped :show="showCart")
      .uk-offcanvas-bar(@click.stop)
        vk-offcanvas-close(@click="showCart = false")

        .empty-cart(v-if="isCartEmpty")
          h2 {{ t("pages.checkout.cart.page_title") }}
          h4 {{ t("pages.checkout.cart.empty_cart")}}

        .cart-summary(v-else)
          h2 {{ t("pages.checkout.cart.page_title") }}
          cart-overview-small(
            :line-items="lineItems"
            :discounts="discounts"
            :order-properties="orderProperties"
          )
          .footer.uk-margin-top
            vk-grid
              h5.uk-margin-remove {{ t("pages.checkout.cart.total_vat_short") }}
              h5.uk-margin-remove.uk-width-expand.uk-text-right {{ formattedVat }}
            vk-grid.uk-margin-remove-top
              h5.uk-margin-remove {{ t("pages.checkout.cart.total") }}
              h5.uk-margin-remove.uk-width-expand.uk-text-right {{ formattedSubTotal }}

            //- p.uk-text-small
            //-   a(href="#" @click.prevent="openDeliveryInfo") {{ t("pages.checkout.cart.purchase_conditions") }}
            p
              button.uk-button.uk-button-primary.uk-width-expand(@click.prevent="goToCheckout") {{ goToCheckoutLabel }}
</template>

<script>
import CartOverviewSmall from "./CartOverviewSmall"
import { mapState, mapGetters, mapActions } from "vuex"
import Settings from '../config/settings'

export default {
  components: {
    CartOverviewSmall
  },
  data() {
    return {
      showCart: this.isCartVisible
    }
  },
  computed: {
    ...mapState("cart", ["isCartVisible", "lineItems", "discounts", "orderProperties"]),
    ...mapGetters("cart", ["isCartEmpty", "itemAmount", "formattedSubTotal", "formattedVat"]),

    goToCheckoutLabel() {
      return Settings.shop === "catering"
        ? this.t("pages.checkout.cart.catering_go_to_checkout")
        : this.t("pages.checkout.cart.go_to_checkout")
    }
  },
  watch: {
    showCart(newValue) {
      this.setIsCartVisible(newValue)
    },
    isCartVisible(newValue) {
      this.showCart = newValue
    }
  },
  methods: {
    ...mapActions("cart", ["setIsCartVisible"]),

    performLink(callback) {
      this.showCart = false
      setTimeout(() => callback(), 500)
    },
    goToCheckout() {
      this.performLink(() => {
        this.$router.push({ name: "Cart" })
      })
    },
    openDeliveryInfo() {
      this.performLink(() => {
        this.$router.push({ name: "Page", params: { permalink: "versandinfo" } })
      })
    },
    toggleCart() {
      
    },
    hideCart() {
      this.showCart = false
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  background: black;
}

.uk-navbar-nav > li > a.cart-button {
  color: white;
}

.amount {
  padding-left: 3px;
}

.cart-summary {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 100%;

  /deep/ .small-cart-overview {
    overflow: auto;
    flex-grow: 1;
    border-top: 1px #f4f4f4 solid;
    border-bottom: 1px #f4f4f4 solid;
    // margin: 0 -40px;
    // padding: 0 40px;
  }
}
</style>
