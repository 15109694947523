<template lang="pug">
.page-header(:style="pageHeaderStyle")
  .image-container
    //- router-link(:to="{ name: 'Home' }")
    //-   img(src="../images/logo.png")
</template>

<script>
import { mapState } from "vuex"
import Settings from '../config/settings'

export default {
  computed: {
    ...mapState("page", ["headerImageUrl"]),

    currentHeaderImageUrl() {
      if (this.headerImageUrl) {
        return this.headerImageUrl
      }
      return `/images/${Settings.headerImageUrl}`
    },
    pageHeaderStyle() {
      return `background-image: url(${this.currentHeaderImageUrl})`
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;

  min-height: 140px;
  width: 100%;
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    // max-height: 400px;
    // max-width: 80%;
    padding: 30px 20px;
  }
}

@media (min-width: 768px) {
  .page-header {
    min-height: 350px;
    min-height: 35vh;
  }
}

@media (min-width: 1170px) {
  .page-header {
    min-height: 450px;
  }
}
</style>
