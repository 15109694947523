<template lang="pug">
  div
    .uk-text-center
      a.account(
        href="#"
        @click.prevent="showAccount = !showAccount"
      )
        span(uk-icon="user")
        .uk-text-smaller {{ t("navigation.my_account") }}

    transition(name="fade")
      #my-account.account-form(v-if="showAccount" @click="showAccount = false")
        .content(@click.stop)
          inline-sign-in(v-if="shouldShowLogin" @didTapSignUp="isSignUp = true")
          inline-user(v-else-if="shouldShowUser")
          p(v-else) sign up
</template>

<script>
import InlineSignIn from "./InlineSignIn"
import InlineUser from "./InlineUser"
import { mapGetters } from "vuex"

export default {
  components: {
    InlineSignIn,
    InlineUser
  },
  watch: {
    $route() {
      this.showAccount = false
    },
    showAccount(newValue) {
      if (newValue) {
        return
      }
      this.isSignUp = false
    }
  },
  data() {
    return {
      showAccount: false,
      isSignUp: false
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),

    shouldShowLogin() {
      if (this.isSignUp || this.currentUser) {
        return false
      }
      return true
    },
    shouldShowUser() {
      if (this.currentUser) {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.account {
  cursor: pointer;
}

#my-account {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /deep/ a {
    color: #666;
  }

  &.account-form {
    .content {
      width: 280px;
      top: 120px;
      right: 0;
      padding: 30px;
      background: white;
      position: absolute;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
