<template lang="pug">
  div
    div(v-for="key in Object.keys(variants)")
      | {{ titleForVariantKey(key) }}
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    variants: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["organization"])
  },
  methods: {
    titleForVariantKey(key) {
      const variant = this.organization.productVariants.find(productVariant => productVariant.id == parseInt(key.split("variant")[1]))
      if (!variant) {
        return null
      }
      const option = variant.productVariantOptions.find(productVariantOption => productVariantOption.id === this.variants[key])
      return option && option.title
    }
  }
}
</script>
