import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=3800b5a1&scoped=true&lang=pug&"
import script from "./PageHeader.vue?vue&type=script&lang=js&"
export * from "./PageHeader.vue?vue&type=script&lang=js&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=3800b5a1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3800b5a1",
  null
  
)

export default component.exports