import ContentService from "../services/contentService"

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isLoadingFooter: false,
    contents: {},
    footer: [],
    navigation: [],
    headerImageUrl: null
  },
  getters: {
    contentFor: state => permalink => {
      return state.contents[permalink]
    }
  },
  mutations: {
    setHeaderImageUrl(state, imageUrl) {
      state.headerImageUrl = imageUrl
    },
    setContent(state, { permalink, content }) {
      state.contents = {
        ...state.contents,
        [permalink]: content
      }
    },
    setFooter(state, footer) {
      state.footer = footer
    },
    setNavigation(state, navigation) {
      state.navigation = navigation
    }
  },
  actions: {
    setHeaderImageUrl({ commit }, imageUrl) {
      commit("setHeaderImageUrl", imageUrl)
    },
    async loadContent({ state, commit }, permalink) {
      commit("setContent", { permalink, content: null })
      state.isLoading = true
      try {
        const content = await ContentService.get(permalink)
        commit("setContent", { permalink, content })
      } catch (error) {
        console.log("content error: ", error)
      } finally {
        state.isLoading = false
      }
    },
    async loadFooter({ state, commit }) {
      commit("setFooter", [])
      state.isLoadingFooter = true
      try {
        const contents = await ContentService.getFooter()
        commit("setFooter", contents)
      } catch (error) {
        console.log("content error: ", error)
      } finally {
        state.isLoadingFooter = false
      }
    },
    async loadNavigation({ state, commit }) {
      commit("setNavigation", [])
      state.isLoading = true
      try {
        const navigation = await ContentService.getNavigation()
        commit("setNavigation", navigation)
      } catch (error) {
        console.log("navigation error: ", error)
      } finally {
        state.isLoading = false
      }
    }
  }
}
