<template lang="pug">
.app(:class="mainClass" @click="handlePageClick")
  header
    navigation
    page-header

  router-view

  page-footer
</template>

<script>
import "moment/locale/de"
import Vue from "vue"
import router from "./config/router"
import store from "./store"
import PageHeader from "./components/PageHeader"
import Navigation from "./components/Navigation"
import PageFooter from "./components/PageFooter"
import { mapState, mapActions } from "vuex"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import Settings from "./config/settings"

if (Settings.sentryDSN) {
  Sentry.init({
    Vue,
    dsn: Settings.sentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "https://feinkost-kantine.de/", "https://www.feinkost-kantine.de", /^\//],
      }),
    ]
  });
}

export default {
  router,
  store,
  components: {
    PageHeader,
    Navigation,
    PageFooter
  },
  computed: {
    ...mapState("cart", ["isCartVisible"]),

    mainClass() {
      return `page-${this.$route.name || "home"}`.toLowerCase()
    }
  },
  methods: {
    ...mapActions("cart", ["setIsCartVisible"]),
    ...mapActions("page", ["loadNavigation"]),

    handlePageClick(event) {
      if (!this.isCartVisible) {
        return
      }

      this.setIsCartVisible(false)
      event.preventDefault()
      event.stopPropagation()
    }
  },
  created() {
    this.loadNavigation()
  }
}
</script>

<style lang="scss" scoped>
.uk-container {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
