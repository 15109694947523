import OrderService from "../services/orderService"

export default {
  namespaced: true,
  state: {
    isLoading: false,
    orders: []
  },
  mutations: {
    setOrders(state, orders) {
      state.orders = orders || []
    }
  },
  actions: {
    async loadOrders({ commit, state }) {
      state.isLoading = true
      try {
        const orders = await OrderService.index()
        commit("setOrders", orders)
      } catch (error) {
        console.log("my orders error: ", error)
      } finally {
        state.isLoading = false
      }
    }
  }
}
