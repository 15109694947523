import { PATH, LINE_ITEMS_PATH, ADDRESS_PATH, PAYMENT_PATH, DISCOUNT_PATH, ORDER_PROPERTIES_PATH, ORDER_PATH, OFFERS_PATH } from "../config/constants"
import http from "./http"

export default {
  async list() {
    const { data: lineItems } = await http.get(PATH)
    return lineItems
  },
  async add(product, dateKey, variants) {
    const data = {
      id: product.id,
      dateKey,
      variants
    }
    const { data: cart } = await http.post(LINE_ITEMS_PATH, data)
    return cart
  },
  async remove(product, dateKey, variantsKey) {
    const { data: cart } = await http.delete(`${LINE_ITEMS_PATH}/${product.id}?date_key=${dateKey}&variants_key=${variantsKey || ""}`)
    return cart
  },
  async removeAll(product, dateKey, variantsKey) {
    const { data: cart } = await http.delete(`${LINE_ITEMS_PATH}/${product.id}?date_key=${dateKey}&variants_key=${variantsKey || ""}&remove_all=true`)
    return cart
  },
  async setNotes(notes) {
    const data = {
      notes
    }
    const { data: cart } = await http.post(PATH, data)
    return cart
  },
  async setAddress(address) {
    const data = {
      address
    }
    const { data: cart } = await http.post(ADDRESS_PATH, data)
    return cart
  },
  async setPaymentMethod(paymentMethod) {
    const data = {
      paymentMethod
    }
    const { data: cart } = await http.post(PAYMENT_PATH, data)
    return cart
  },
  async placeOrder({ useUserDiscounts }) {
    const { data: orderResult } = await http.post(ORDER_PATH, { useUserDiscounts })
    return orderResult
  },
  async addDiscountCode(code) {
    const { data: result } = await http.post(DISCOUNT_PATH, { code })
    return result
  },
  async addOrderProperties(uuid, properties) {
    const { data: result } = await http.post(ORDER_PROPERTIES_PATH, { uuid, properties })
    return result
  },
  async updateOrderProperties(uuid, properties) {
    const { data: result } = await http.put(`${ORDER_PROPERTIES_PATH}/${uuid}`, { properties })
    return result
  },
  async addOffer() {
    const { data: offerResult } = await http.post(OFFERS_PATH)
    return offerResult
  },
  async myOffers() {
    const { data: offersResult } = await http.get(OFFERS_PATH)
    return offersResult
  },
  async orderOffer(offer) {
    const { data: offersResult } = await http.put(`${OFFERS_PATH}/${offer.id}`)
    return offersResult
  },
  async reorder(offerId, propertiesId) {
    const { data: result } = await http.put(`${PATH}/current?order_id=${offerId}&properties_id=${propertiesId}`)
    return result
  }
}
