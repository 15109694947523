import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"
import Settings from "./settings"

const globalStore = store

const Home = () => import("../pages/Home")
const ContentPage = () => import("../pages/ContentPage")
const Catalog = () => import("../pages/Catalog")
const ProductGroup = () => import("../pages/ProductGroup")
const Category = () => import("../pages/Category")
const Catering = () => import("../pages/Catering")
const ProductSelection = () => import("../pages/ProductSelection")
const Cart = () => import("../pages/Cart")
const SignUp = () => import("../pages/SignUp")
const SignIn = () => import("../pages/SignIn")
const ResendConfirmation = () => import("./../pages/ResendConfirmation")
const PasswordReset = () => import("./../pages/PasswordReset")
const PasswordChange = () => import("./../pages/PasswordChange")
const MyAccount = () => import("./../pages/MyAccount")
const MyAddress = () => import("./../pages/MyAddress")
const MyOrders = () => import("./../pages/MyOrders")
const MyOffers = () => import("./../pages/MyOffers")
const OrderDetails = () => import("./../pages/OrderDetails")
const Checkout = () => import("./../pages/Checkout")
const CheckoutAddress = () => import("./../pages/CheckoutAddress")
const CheckoutInvoiceAddress = () => import("./../pages/CheckoutInvoiceAddress")
const CheckoutPayment = () => import("./../pages/CheckoutPayment")
const CheckoutConfirmation = () => import("./../pages/CheckoutConfirmation")
const OrderCompleted = () => import("./../pages/OrderCompleted")
const OrderFailed = () => import("./../pages/OrderFailed")

Vue.use(VueRouter)

const isCatering = Settings.shop === "catering"

function checkoutChildren() {
  if (isCatering) {
    return [
      {
        path: "warenkorb",
        name: "Cart",
        component: Cart,
        meta: { key: "cart" }
      },
      {
        path: "adresse",
        name: "Address",
        component: CheckoutInvoiceAddress,
        meta: { key: "invoiceAddress" }
      },
      {
        path: "bestaetigung",
        name: "Confirmation",
        component: CheckoutConfirmation,
        meta: { key: "confirmation" }
      }
    ]
  }
  return [
    {
      path: "warenkorb",
      name: "Cart",
      component: Cart,
      meta: { key: "cart" }
    },
    {
      path: "addresse",
      name: "Address",
      component: CheckoutAddress,
      meta: { key: "address" }
    },
    {
      path: "bezahlung",
      name: "Payment",
      component: CheckoutPayment,
      meta: { key: "payment" }
    },
    {
      path: "bestaetigung",
      name: "Confirmation",
      component: CheckoutConfirmation,
      meta: { key: "confirmation" }
    }
  ]
}

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true
  },
  {
    path: "/kantine/:weekday",
    name: "Catalog",
    component: Catalog,
    props: true,
    meta: { authenticated: true }
  },
  {
    path: "/catering/:id",
    name: "Catering",
    props: true,
    component: Catering,
    meta: { authenticated: true, catering: true, needSessionId: true },
    redirect: { name: "ProductGroup" },
    children: [
      {
        path: "eckdaten",
        name: "ProductGroup",
        component: ProductGroup,
        meta: { key: "group" }
      },
      // {
      //   path: "essen",
      //   name: "Category",
      //   component: Category,
      //   meta: { key: "products" },
      //   props: categoryParams
      // },
      // {
      //   path: "getraenke",
      //   name: "Drinks",
      //   component: Category,
      //   meta: { key: "productsDrinks" },
      //   props: {
      //     category: 'getränke'
      //   }
      // },
      {
        path: "equipment",
        name: "Equipment",
        component: ProductSelection,
        meta: { key: "equipment", needProperties: "useEquipment" },
        props: {
          category: 'equipment'
        }
      },
      {
        path: "personal",
        name: "Staff",
        component: ProductSelection,
        meta: { key: "staff", needProperties: "useService" },
        props: {
          category: 'personal'
        }
      },
      {
        path: ":category",
        name: "Category",
        component: Category,
        meta: { key: "products", ignoreInNavigation: true },
        props: true
      },
    ]
  },
  {
    path: "/kategorien/:category",
    name: "CategoryPreview",
    component: Category,
    props: true
  },
  {
    path: "/seiten/:permalink",
    name: "Page",
    component: ContentPage,
    props: true
  },
  {
    path: "/bestellen",
    name: "Checkout",
    component: Checkout,
    meta: { authenticated: true, checkout: true },
    redirect: { name: "Cart" },
    children: checkoutChildren()
  },
  {
    path: "/bestellungen/abgeschlossen",
    name: "OrderCompleted",
    component: OrderCompleted
  },
  {
    path: "/bestellungen/fehlerhaft",
    name: "OrderFailed",
    component: OrderFailed
  },
  {
    path: "/registrierung/:slug?",
    name: "SignUp",
    component: SignUp,
    props: true
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/bestaetigung",
    name: "ResendConfirmation",
    component: ResendConfirmation
  },
  {
    path: "/passwort/neu",
    name: "ResetPassword",
    component: PasswordReset
  },
  {
    path: "/passwort/bearbeiten",
    component: PasswordChange,
    name: "EditPassword",
    props: route => ({ resetPasswordToken: route.query.token })
  },
  {
    path: "/mein-konto",
    name: "MyAccount",
    component: MyAccount,
    meta: { authenticated: true },
    redirect: { name: "MyOrders" },
    children: [
      {
        path: "/bestellungen",
        name: "MyOrders",
        component: MyOrders,
        meta: { authenticated: true },
      },
      {
        path: "/angebote",
        name: "MyOffers",
        component: MyOffers,
        meta: { authenticated: true }
      }
    ]
  },
  {
    path: "/mein-konto/bestellungen/:id",
    name: "OrderDetails",
    component: OrderDetails,
    meta: { authenticated: true },
    props: true
  },
  {
    path: "/mein-konto/adresse",
    name: "MyAddress",
    component: MyAddress,
    meta: { authenticated: true }
  },
  {
    path: "*",
    component: ContentPage,
    props: route => ({ permalink: "not_found" })
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.auth.restored) {
    await store.dispatch("restoreLocalSession")
    if (store.state.auth.user) {
      await store.dispatch("cart/loadCart")
    }
  }

  let accessAllowed = true

  to.matched.forEach(async (record) => {
    if (record.meta.needSessionId) {
      const result = await store.dispatch("cart/hasOrderProperties", to.params.id)
      if (!result) {
        return next({
          name: "Home"
        })
      }
    }

    if (record.meta.cateringInfo) {
      return next({
        name: "Home"
      })
    }

    if (!record.meta.authenticated) {
      return
    }

    if (!store.state.auth.user) {
      accessAllowed = false
      return
    }

    const { role } = store.state.auth.user
    const { authenticated } = record.meta

    if (typeof authenticated === "string") {
      accessAllowed = role === authenticated
    } else if (Array.isArray(authenticated)) {
      accessAllowed = authenticated.includes(role)
    } else {
      // route's `meta.authenticated` can be set to just `true` in order
      //   to allow any authenticated user access the page
      accessAllowed = !!authenticated
    }
  })

  if (to && to.matched && to.matched.length && to.matched[0].meta && to.matched[0].meta.checkout && to.meta && to.meta.key) {
    if (!store.getters["cart/visitableStates"].includes(to.meta.key)) {
      return next({
        name: "Cart"
      })
    }
  }
  if (!accessAllowed) {
    return next({
      name: "SignIn",
      params: { nextUrl: to.fullPath }
    })
  }

  next()
})

function categoryParams(route) {
  const orderProperties = globalStore.state.cart.orderProperties.find(p => p.uuid === route.params.id)

  if (!orderProperties || !orderProperties.properties.category) {
    document.location.href = "/"
    return
  }
  return {
    category: orderProperties.properties.category
  }
}

export default router
