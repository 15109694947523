<template lang="pug">
  .small-cart-overview(:uk-grid="shouldUseGrid")
    template(v-if="groupedByVariant")
      template(v-for="(group, index) in groupedLineItems")
        .group.uk-section-xsmall(:class="groupClass")
          h4.uk-margin-remove {{ groupedTitle(group) }}
            .uk-inline(v-if="hasContext")
              a(uk-icon="triangle-down" href="#" @click.prevent)
              vk-drop(mode="click" position="right-center")
                vk-card
                  submit-button.uk-width-1-1(
                    :label="t('pages.checkout.cart.reorder.title')"
                    :is-submitting="isReordering"
                    @click.native.prevent.stop="$emit('reorder', group.id)"
                  )
                  submit-button.uk-width-1-1.uk-margin-small-top(
                    v-if="isGroupCancelable(group)"
                    :label="t('pages.checkout.cart.cancelation.cancel_order')"
                    :is-submitting="isCanceling"
                    @click.native.prevent.stop="$emit('cancelOrder', dateKey)"
                  )
          
          p.uk-text-small(v-html="orderPropertiesDescription(group)")

          template(v-for="lineItemGroup in group.groupedLineItems")
            line-item-small(
              v-for="lineItem in lineItemGroup"
              :key="`${lineItem.productId}-${group.id}-${lineItem.variantsKey}`"
              :date-key="lineItem.dateKey"
              :line-item="lineItem"
              :read-only="readOnly"
              :plain-price="plainPrice"
            )

    template(v-else)
      template(v-for="dateKey in Object.keys(dateGroups)")
        .group(:class="groupClassFor(dateKey)")
          h4.uk-margin-remove {{ dateFor(dateKey) }}
            .uk-inline(v-if="isDayCancelable(dateKey)")
              a(uk-icon="triangle-down" href="#" @click.prevent)
              vk-drop(mode="click" position="right-center")
                vk-card
                  submit-button.uk-width-1-1(
                    :label="t('pages.checkout.cart.cancelation.cancel_order')"
                    :is-submitting="isCanceling"
                    @click.native.prevent.stop="$emit('cancelOrder', dateKey)"
                  )
          
          line-item-small(
            v-for="lineItem in dateGroups[dateKey]"
            :key="`${lineItem.productId}-${dateKey}-${lineItem.variantsKey}`"
            :date-key="dateKey"
            :line-item="lineItem"
            :read-only="readOnly"
            :plain-price="plainPrice"
          )
          line-item-small(
            v-if="discountLineItemFor(dateKey)"
            :date-key="dateKey"
            :line-item="discountLineItemFor(dateKey)"
            :read-only="true"
            :plain-price="plainPrice"
          )
    .group(
      v-if="discountLineItem || creditLineItem"
      :class="groupClass"
    )
      h4.uk-margin-remove {{ t("pages.checkout.cart.used_discounts") }}

      line-item-small(
        v-if="discountLineItem"
        date-key=""
        :line-item="discountLineItem"
        :read-only="true"
        :plain-price="plainPrice"
      )

      line-item-small(
        v-if="creditLineItem"
        date-key=""
        :line-item="creditLineItem"
        :read-only="true"
        :plain-price="plainPrice"
      )

      //- line-item-small(
      //-   v-if="spendPointHistories.length"
      //-   v-for="pointHistory in spendPointHistories"
      //-   :key="pointHistory.id"
      //-   date-key=""
      //-   :line-item="pointHistoryLineItemFor(pointHistory)"
      //-   :read-only="true"
      //-   :plain-price="plainPrice"
      //-   :should-format-price="pointHistory.action === 'spend'"
      //- )

    .group(
      v-if="canEarnPoints && (earnedPointHistories.length || formattedOrderDiscount)"
    )
      h4.uk-margin-remove
        u {{ t("pages.checkout.cart.credit_information") }}
      .uk-margin(style="margin: 5px 0px !important;")
        p.uk-text-small(style="margin: 0" v-if="earnedPointHistories.length")
          | {{ t("pages.checkout.cart.order_points_earned") }}
        p.uk-text-small(style="margin: 0" v-if="formattedOrderDiscount")
          | {{ t("pages.checkout.cart.order_cancel_amount", { amount: formattedOrderDiscount }) }}
</template>

<script>
import LineItemSmall from "../components/LineItemSmall"
import SubmitButton from "../components/SubmitButton"
import { groupArray, dateFor, filteredAndGroupedLineItems } from "../helpers/functions"
import { mapGetters, mapState, mapActions } from "vuex"
import Settings from '../config/settings'
import { OUTPUT_DATE_FORMAT } from '../config/constants'
import moment from 'moment'

function filteredPointHistories(pointHistories, action) {
  if (!pointHistories || !pointHistories.length) {
      return []
    }

    return pointHistories.filter(pointHistory => pointHistory.action === action)
}

export default {
  components: {
    LineItemSmall,
    SubmitButton
  },
  props: {
    lineItems: {
      type: Array,
      required: true
    },
    discounts: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    shouldUseGrid: {
      type: Boolean,
      default: false
    },
    groupClass: {
      type: String,
      default: "uk-section-xsmall"
    },
    plainPrice: {
      type: Boolean,
      default: false
    },
    pointHistories: {
      type: Array,
      default: () => []
    },
    isCancelable: {
      type: Boolean,
      default: false
    },
    isCanceling: {
      type: Boolean,
      default: false
    },
    isReordering: {
      type: Boolean,
      default: false
    },
    formattedOrderDiscount: {
      type: String,
      default: null
    },
    ignoreVariantGroups: {
      type: Boolean,
      default: false
    },
    orderProperties: {
      type: Array,
      default: () => []
    },
    hasContext: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (!this.catalogs.length) {
      this.loadCatalogs()
    }
  },
  computed: {
    ...mapGetters("catalog", ["isOrderableOn"]),
    ...mapState("catalog", ["catalogs"]),
    ...mapState(["canEarnPoints"]),

    groupedByVariant() {
      return Settings.splitByVariantId && !this.ignoreVariantGroups
    },
    groupedLineItems() {
      if (!this.groupedByVariant) {
        return []
      }

      return this.orderProperties.map(properties => {
        const products = this.catalogs.map((c, index) => c.products.map(prd => {
          return { ...prd, ...{ catalogIndex: index } }
        })).flat()
        const lineItems = this.lineItems.map(lineItem => {
          const catalog = this.catalogs.find(c =>  c.products.find(p => p.id === lineItem.productId))
          return {
            ...lineItem,
            ...{ catalogId: catalog && catalog.id }
          }
        })

        return {
          id: properties.uuid,
          groupedLineItems: filteredAndGroupedLineItems(lineItems, properties.uuid, products),
          properties: properties.properties
        }
      })
      .filter(g => g.groupedLineItems.length)
    },
    dateGroups() {
      return groupArray(this.lineItems, ["dateKey"])
    },
    colspan() {
      return this.readOnly ? 3 : 4
    },
    discountLineItem() {
      const discount = this.discounts.find(d => d.isDiscount && !d.isUserDiscount)
      if (!discount) {
        return null
      }
      return {
        name: discount.code || discount.name,
        price: discount.value
      }
    },
    creditLineItem() {
      let amount = 0
      this.discounts.forEach(discount => {
        if (discount.isUserDiscount) {
          amount += discount.value
        }
      })
      if (amount === 0) {
        return null
      }
      return {
        name: "Guthaben",
        price: amount
      }
    },
    earnedPointHistories() {
      return filteredPointHistories(this.pointHistories, "earn")
    }
  },
  methods: {
    ...mapActions("catalog", ["loadCatalogs"]),

    discountLineItemFor(dateKey) {
      const discount = this.discounts.find(d => d.dateKey === dateKey && !d.isDiscount)
      if (!discount) {
        return
      }
      return {
        name: discount.name,
        price: discount.value,
        canceledAt: discount.canceledAt,
        amount: 1
      }
    },
    pointHistoryLineItemFor(pointHistory) {
      return {
        name: this.t(`pages.checkout.cart.points.action.${pointHistory.action}`, { points: pointHistory.amount }),
        price: pointHistory.action === "spend" ? -pointHistory.totalAmountPrice : pointHistory.amount,
        amount: null
      }
    },
    dateFor(dateKey) {
      return dateFor(dateKey)
    },
    isDayCanceled(dateKey) {
      return Boolean(this.dateGroups[dateKey] && this.dateGroups[dateKey].find(li => li.canceledAt))
    },
    isDayCancelable(dateKey) {
      const date = dateFor(dateKey, false)
      if (date < new Date()) {
        return false
      }
      return this.isCancelable && this.isOrderableOn(date) && !this.isDayCanceled(dateKey)
    },
    groupClassFor(dateKey) {
      let classNames = [this.groupClass || ""]

      if (this.isDayCanceled(dateKey)) {
        classNames.push("canceled")
      }

      return classNames.join(" ")
    },
    groupedTitle(group) {
      if (!group.properties) {
        return
      }
      const date = moment(group.properties.date).format(OUTPUT_DATE_FORMAT)
      return `${date} ${group.properties.category}`
    },
    isGroupCancelable(group) {
      return false // TODO
    },
    orderPropertiesDescription(group) {
      const properties = group.properties
      return [
        ['Meeting startet', properties.meetingStart],
        ['Meeting dauer', properties.meetingDuration],
        ['Personenanzahl', properties.personCount],
        ['Referenztext', properties.reference]
      ]
      .filter(entry => entry[1] && entry[1].length)
      .map(entry => entry.join(': '))
      .join("<br>")
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  border-bottom: 1px #f4f4f4 solid;

  &:last-child {
    border-bottom: 0;
  }
}

.canceled {
  text-decoration: line-through;
}
</style>
