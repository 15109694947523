import http from "./http"
const PATH = "/api/catalogs"

export default {
  async list() {
    const { data: catalogs } = await http.get(PATH)
    return catalogs
  },
  async dishesOfTheDay(weekday) {
    const { data: dishesOfTheDay } = await http.get(`${PATH}/${weekday}`)
    return dishesOfTheDay
  }
}
