<template lang="pug">
  footer
    .uk-container
      vk-grid.uk-padding.uk-padding-remove-horizontal.uk-text-small
        div(
          v-for="col in cols"
          :class="`uk-width-1-${cols.length}@m`"
        )
          h2 {{ col.title }}
          vue-markdown {{ col.content }}
</template>

<script>
import VueMarkdown from "vue-markdown"
import { mapState, mapActions } from "vuex"

export default {
  components: {
    VueMarkdown
  },
  computed: {
    ...mapState("page", ["isLoadingFooter", "footer"]),

    cols() {
      return [
        this.footer.find(f => f.permalink === 'footer-contact'),
        this.footer.find(f => f.permalink === 'footer-opening-hours'),
        this.footer.find(f => f.permalink === 'footer-imprint')
      ].map(col => {
        if (!col) {
          return null
        }
        return {
          title: col.title,
          content: col.body
        }
      })
    }
  },
  methods: {
    ...mapActions("page", ["loadFooter"]),

    updateLinks() {
      this.$el.querySelectorAll("a").forEach(a => {
      if (!a.href || !a.getAttribute("href").startsWith("/seiten/")) {
        return
      }
      a.addEventListener("click", event => {
        event.preventDefault()
        event.stopPropagation()
        const permalink = event.target.getAttribute("href").split("/")[2]
        this.$router.push({ name: "Page", params: { permalink }})
      })
    })
    }
  },
  async created() {
    await this.loadFooter()
    this.updateLinks()
  },
  mounted() {
    this.updateLinks()
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: #333;
  color: white;

  h2 {
    color: white;
  }

  /deep/ {
    a {
      color: white;
      text-decoration: underline;
    }

    h4 {
      color: white;
      margin: 3px 0 -12px;
    }
  }
}
</style>
