<template lang="pug">
  div
    div(:class="{ 'uk-width-1-1': fullscreen, 'uk-inline': icon }")
      span.uk-form-icon(v-if="icon" :uk-icon="`icon: ${icon}`")
      label(
        v-if="fieldLabel"
        :class="labelClass"
        :for="id"
      ) {{ fieldLabel }}
      textarea.uk-textarea(
        v-if="multiline"
        :value="value"
        :id="id"
        :name="name"
        :placeholder="label"
        @input="input"
        @blur="input"
        ref="input"
        :disabled="disabled"
        :rows="rows"
      )
      template(v-else)
        datepicker#datepicker(
          v-if="type === 'datepicker'"
          :language="de"
          input-class="uk-input"
          :wrapper-class="containerClass"
          ref="input"
          :disabled="disabled"
          :placeholder="label"
          :id="id"
          :name="name"
          :server-errors="serverErrors"
          :value="value"
          :disabled-dates="datepickerState.disabled"
          :monday-first="true"
          @input="datepickerInput"
          @blur="datepickerInput"
        )
        div(v-else-if="containerClass" :class="containerClass")
          input.uk-input(
            :id="id"
            :type="type"
            :name="name"
            :server-errors="serverErrors"
            :placeholder="label"
            :value="value"
            :min="minValue"
            @input="input"
            @blur="input"
            ref="input"
            :disabled="disabled"
          )
        input.uk-input(
          v-else
          :id="id"
          :type="type"
          :name="name"
          :server-errors="serverErrors"
          :placeholder="label"
          :value="value"
          :min="minValue"
          @input="input"
          @blur="input"
          ref="input"
          :disabled="disabled"
        )
    validation-errors(:label="label" :validations="validations")
    .uk-text-danger.uk-text-small(
      v-for="(error, index) in serverErrors"
      :key="index"
    )
      | {{ error }}
    .uk-text-small(v-if="hint") {{ hint }}
</template>

<script>
import Datepicker from "vuejs-datepicker"
import ValidationErrors from "./ValidationErrors"
import { uuid } from "../helpers/functions"
import moment from 'moment'
import { de } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    ValidationErrors,
    Datepicker
  },
  props: {
    multiline: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      required: true
    },
    serverErrors: {
      type: Array,
      default: () => []
    },
    fieldLabel: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    validations: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    minValue: {
      type: [String, Number],
      default: null
    },
    containerClass: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: null
    },
    datepickerState: {
      type: Object,
      default: () => { return { disabled: {} }}
    }
  },
  emits: ["input"],
  data() {
    return {
      id: uuid(),
      de
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => this.$refs.input.focus())
    }
  },
  methods: {
    input() {
      if (this.validations) {
        this.validations.$touch()
      }
      if (this.$refs.input) {
        this.$emit("input", this.$refs.input.value)
      }
    },
    datepickerInput(value) {
      if (this.validations) {
        this.validations.$touch()
      }
      this.$emit("input", value ? moment(value).format("YYYY-MM-DD") : null)
    }
  }
}
</script>
