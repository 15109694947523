<template lang="pug">
  .user.inline-form.uk-text-center
    h3 {{ t("pages.account.page_title") }}
    h3.name {{ name }}

    p.uk-text-small(v-if="!isCatering") {{ t("pages.account.description") }}
    p.uk-text-small
      router-link(:to="{ name: 'MyAccount' }") {{ t("pages.account.my_account") }}

    a.uk-text-small.logout(href="#" @click.prevent="logout()")
      | {{ t("pages.account.logout") }}
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(["currentUser", "isCatering"]),

    name() {
      if (this.currentUser && this.currentUser.address && this.currentUser.address.firstName && this.currentUser.address.firstName.length) {
        return [this.currentUser.address.firstName, this.currentUser.address.lastName].join(" ")
      }
      return this.currentUser.email
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../packs/stylesheets/abstracts/variables.scss";

h3 {
  margin: 0;

  &.name {
    font-weight: normal;
    margin-bottom: 10px;
  }
}

.logout {
  display: block;
  margin: 20px -30px -30px;
  border-top: 1px #EDEDED solid;
  padding-top: 10px;
  padding-bottom: 15px;

  font-weight: bold;
  text-decoration: none !important;
}
</style>
