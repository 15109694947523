<template lang="pug">
  .uk-margin.details
    p.uk-text-small
      span(v-if="lineItem.amount && product && !['per_usage'].includes(amountType)")
        | {{ lineItem.amount }}x 
      span {{ lineItem.name }}
      selected-variants(
        v-if="lineItem.variants"
        :variants="lineItem.variants"
      )

    .uk-text-small.uk-text-right(v-if="plainPrice || (product && amountType !== 'normal')") {{ amountPrice }}
    vk-grid.uk-margin-small-top(v-else)
      div.uk-width-expand
        line-item-control(
          v-if="!readOnly"
          :line-item="lineItem"
          :date-key="dateKey"
        )
      .price {{ price }}
</template>

<script>
import LineItemControl from "./LineItemControl"
import SelectedVariants from "./SelectedVariants"
import { formatPrice } from "../helpers/functions"
import { mapGetters } from 'vuex'

export default {
  components: {
    LineItemControl,
    SelectedVariants
  },
  props: {
    dateKey: {
      type: String,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    plainPrice: {
      type: Boolean,
      default: false
    },
    shouldFormatPrice: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters("catalog", ["findProduct"]),

    product() {
      return this.findProduct(this.lineItem.productId)
    },
    price() {
      if (this.shouldFormatPrice) {
        return formatPrice(this.lineItem.price)
      }
      return this.lineItem.price
    },
    amountType() {
      return this.product && this.product.amountType
    },
    amountPrice() {
      if (this.product && this.amountType === "per_usage") {
        return this.t("pages.checkout.cart.total_after_usage")
      }
      return this.price
    }
  }
}
</script>

<style lang="scss" scoped>
h4, p {
  margin: 0
}

.control-cell {
  min-width: 85px;
}

.price-cell {
  @media (max-width: 640px) {
    margin-top: -35px;
  }
}
</style>
