import { OUTPUT_DATE_FORMAT } from "../config/constants"
import moment from "moment"
import Settings from "../config/settings"

export function formatPrice(price) {
  if (Intl) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)
  }
  return `${price} €`
}

export function groupArray(array, key) {
  return array.reduce((result, value) => {
    (result[value[key]] = result[value[key]] || []).push(value)
    return result
  }, {})
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function salutationOptions() {
  let options = [
    { value: null, label: I18n.t("helpers.select.prompt") }
  ]
  I18n.t("pages.checkout.address.form.values.salutation").forEach(label => {
    options.push({
      value: label,
      label
    })
  })
  return options
}

export function dateFor(dateKey, format=true) {
  const date = moment(dateKey, "YYYYMMDD")
  if (!format) {
    return date
  }
  return date.format(OUTPUT_DATE_FORMAT)
}

export function filteredAndGroupedLineItems(lineItems, groupId, products) {
  let group = {}
  
  const newLineItems = filteredLineItems(lineItems, groupId).sort((left, right) => {
    if (!products) {
      return
    }
    const leftProduct = products.find(prd => left.productId === prd.id)
    const rightProduct = products.find(prd => right.productId === prd.id)
    if (!leftProduct || !rightProduct) {
      return
    }

    return leftProduct.catalogIndex - rightProduct.catalogIndex
  })

  newLineItems.forEach(lineItem => {
    const key = `key-${lineItem.catalogId}`
    if (!Object.keys(group).includes(key)) {
      group[key] = []
    }
    group[key].push(lineItem)
  })
  return Object.values(group)
}

export function filteredLineItems(lineItems, groupId) {
  const variantId = Settings.splitByVariantId

  return lineItems.filter(lineItem => {
    const [id] = lineItem.variants[`variant${variantId}`].split('|')
    return id === groupId
  })
}
